import { RouteNames } from '@/types/routes';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'playground',
    name: RouteNames.PLAYGROUND,
    components: {
      default: () => import('@/modules/Playground/views/Playground.vue'),
    },
  },
];
export default routes;
