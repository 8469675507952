import { RouteNames } from '@/types/routes';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    name: RouteNames.DEBUG,
    path: 'traces',
    redirect: { name: RouteNames.TRACES },
    children: [
      {
        name: RouteNames.TRACES,
        path: '',
        components: {
          default: () => import('@/modules/Traces/views/ListTraces.vue'),
        },
      },
      {
        name: RouteNames.TRACE,
        path: ':traceId',
        components: {
          default: () => import('@/modules/Traces/views/ViewTrace.vue'),
          sidepanel: () => import('@/modules/Traces/components/SpanDetails/SpanDetails.vue'),
        },
      },
    ],
  },
];

export default routes;
