import { User, UserManager } from 'oidc-client-ts';

export const guardForCallbackPage = async (
  userManager: UserManager,
  callback: (user?: User) => Promise<void> | void
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = (await userManager.signinCallback(window.location.href))!;
  await callback(user);
};
