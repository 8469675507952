// TBD - Only placeholder until we start working on this module, paths might change
import { RouteNames } from '@/types/routes';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteNames.KNOWLEDGE,
    redirect: { name: RouteNames.COLLECTIONS },
    children: [
      {
        path: 'collections',
        name: RouteNames.COLLECTIONS,
        redirect: { name: RouteNames.NOTFOUND },
      },
      {
        path: 'search',
        name: RouteNames.SEARCH,
        redirect: { name: RouteNames.NOTFOUND },
      },
    ],
  },
];
export default routes;
