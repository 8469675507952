// TBD - Only placeholder until we start working on this module, paths might change
import { RouteNames } from '@/types/routes';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'finetune',
    name: RouteNames.FINETUNE,
    redirect: { name: RouteNames.NOTFOUND },
  },
];
export default routes;
